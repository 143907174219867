import { Container, Card } from 'react-bootstrap';
import Chart from './Chart.js';

function App() {
  return (
    <div>
      <header className="App-header my-3 ms-4">
        Rentabilidade Petros PP-2
      </header>
      <Container>
        <Card text='white' className="bg-dark mt-4 border-0 mb-4">
          <Card.Header className="bg-secondary p-4">Header</Card.Header>
          <Card.Body className="bg-dark p-4">
            <Card.Title>Retorno acumulado do plano Petros 2</Card.Title>
            <Card.Text className="mx-2 p-4 mx-4">
             Retorno acumulado no plano no periodo foi de +xxx.xx%
            </Card.Text>
            <Chart className="p-4"/>
          </Card.Body>
          <Card.Footer  className="bg-secondary p-4">
            <small>fonte: petros.com.br</small>
          </Card.Footer>
        </Card>
      </Container>
    </div>
  );
}

export default App;
