import React, { Component } from "react";
import Chart from "react-apexcharts";

class App extends Component {
    constructor(props) {
        super(props);

        var data = require('./data/rentabilidade_petros.json');

        //PP2 cumulative
        var data2 = data.map(x => ({ 'x': new Date(new Date(x.Time).getTime()), 'y': (x.PP2) / 100 }))
        let cumrent = 1;
        var cumpp2 = data2.map(({ x, y }) => ({ x, y: cumrent *= (1 + y) }));

        //IPCA cumulative
        var ipcadata = data.map(x => ({ 'x': new Date(new Date(x.Time).getTime()), 'y': (x.IPCA) / 100 }))
        let ipcacumrent = 1;
        var cumipca = ipcadata.map(({ x, y }) => ({ x, y: ipcacumrent *= (1 + y) }));

        //SELIC cumulative
        var selicdata = data.map(x => ({ 'x': new Date(new Date(x.Time).getTime()), 'y': (x.SELIC) / 100 }))
        let seliccumrent = 1;
        var cumselic = selicdata.map(({ x, y }) => ({ x, y: seliccumrent *= (1 + y) }));
        this.state = {
            series: [
                {
                    name: "Plano Petros 2",
                    data: cumpp2,
                    type: 'area'
                },
                {
                    name: "IPCA",
                    data: cumipca,
                    type: 'line'
                },
                {
                    name: "SELIC",
                    data: cumselic,
                    type: 'line'
                }],
            options: {
                colors: ["#00CCff", "#ff9800", '#1eb980'],
                chart: {
                    id: "mainchart",
                    type: ['area'],
                    stacked: false,
                    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
                    animations: {
                        enabled: false
                    },
                    foreColor: "#bbc7d2",
                    height: 360,
                    align: 'center',
                    toolbar: {
                        theme: "dark",
                        tools: {
                            download: true,
                            selection: false,
                            zoom: false,
                            zoomin: false,
                            zoomout: false,
                            pan: false,
                            reset: false,
                        }
                    },
                },
                yaxis: {
                    seriesName: 'PP2',
                    crosshairs: {
                        show: false
                    },
                    tooltip: {
                        enabled: false,
                    },
                    axisBorder: {
                        show: true,
                        color: '#000000',
                    },
                    forceNiceScale: true,
                    axisTicks: {
                        color: "#000000"
                    },
                    labels: {
                        offsetX: -70,
                        minWidth: 90,
                        formatter: function (value) {
                            return (-(1 - value)).toLocaleString('pt-BR', { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 })
                        },
                    },
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'smooth',
                    width: 2,
                },
                fill: {
                    type: ['gradient','solid','solid'],
                    gradient: {
                        shadeIntensity: 0.5,
                        opacityFrom: 0.5,
                        opacityTo: 0.0,
                        stops: [10, 90]
                    },
                },
                xaxis: {
                    tooltip: {
                        enabled: false
                    },
                    type: 'datetime',
                    axisTicks: {
                        color: "#000000"
                    },
                    axisBorder: {
                        show: false,
                    },
                    labels: {
                        format: 'MM/yyyy',
                    }
                },
                grid: {
                    padding: {
                        left: -55
                    },
                    borderColor: "#000",
                },
                tooltip: {
                    theme: "dark",
                    enabled: true,
                    x: {
                        show: true,
                        format: 'MM/yyyy',
                    },
                    y: {
                        formatter: (value) => (-(1 - value)).toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2, minimumFractionDigits: 2 }),
                    },
                },
                noData: {
                    text: 'Carregando...'
                },
                legend: { show: false },
            },
        };
    }

    render() {
        return (
            <div>
                <Chart
                    options={this.state.options}
                    series={this.state.series}
                    type="area"
                    height={360}
                />
            </div>
        );
    }
}

export default App;